import React from "react"
import { Col, FloatingLabel, Form } from "react-bootstrap"
import { useFormContext } from "react-hook-form"

type FormInputProps = {
  label?: string
  name: string
  type?: string
  value?: string
  rows?: number
  onChange?: any
  as?: any | undefined
  style?: React.CSSProperties
  placeholder?: string
  plaintext?: boolean
  readOnly?: boolean
  required?: boolean
  disabled?: boolean
  hidden?: boolean
  feedbackStyle?: React.CSSProperties
  min?: string | number
  children?: React.ReactNode
}

const FormInput: React.FC<FormInputProps> = ( {
  label,
  name,
  type = "text",
  value,
  as: lookLike,
  style,
  feedbackStyle = { textAlign: "right" },
  children,
  ...props
} ) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext()

  const handleChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    setValue( name, e.target.value )
  }

  return (
    <div className="mb-4">
      <Form.Group as={ Col } controlId={ name }>
        { type === "select" ? (
          <>
            <Form.Select
              { ...register( name ) }
              isInvalid={ !!errors[ name ] }
              value={ value }
              onChange={ handleChange }
              defaultValue=""
              style={ { ...style, height: "calc(3.5rem + 2px)" } }
              plaintext={ props.plaintext }
              readOnly={ props.readOnly }
              disabled={ props.disabled }
              required={ props.required }
              hidden={ props.hidden }
              min={ props.min }
              { ...props }
            >
              { children }
            </Form.Select>
            <Form.Control.Feedback type="invalid" style={ { ...feedbackStyle } }>
              { errors[ name ]?.message as string }
            </Form.Control.Feedback>
          </>
        ) : (
          <FloatingLabel label={ label }>
            <Form.Control
              type={ type }
              placeholder={ props.placeholder ? label : "" }
              { ...register( name ) }
              isInvalid={ !!errors[ name ] }
              as={ lookLike }
              value={ value }
              onChange={ handleChange }
              style={ style }
              plaintext={ props.plaintext }
              readOnly={ props.readOnly }
              disabled={ props.disabled }
              required={ props.required }
              rows={ props.rows }
              hidden={ props.hidden }
              min={ props.min }
              { ...props }
            />
            <Form.Control.Feedback type="invalid" style={ { ...feedbackStyle } }>
              { errors[ name ]?.message as string }
            </Form.Control.Feedback>
          </FloatingLabel>
        ) }
      </Form.Group>
    </div>
  )
}

export default FormInput
