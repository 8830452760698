import MainLayout from "@/Layout";
import useStore from "@/store";
import { useAuthContext } from "@components/AuthContext";
import type React from "react";
import { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import MySpinner from "@/components/Spinner";

type ProtectedRouteProps = {
	children?: React.ReactNode;
	errorPath?: string;
};

const ProtectedRoute = ({
	children,
	errorPath = "/error",
}: ProtectedRouteProps) => {
	const { isAuthenticated, updateAuthentication } = useAuthContext();
	const { auth_token, setRequestLoading } = useStore();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const checkAuth = async () => {
			setRequestLoading(true);
			if (auth_token) {
				updateAuthentication(true);
			} else {
				updateAuthentication(false);
				navigate("/");
			}
			setIsLoading(false);
			setRequestLoading(false);
		};

		checkAuth();
	}, [auth_token, updateAuthentication, setRequestLoading]);

	if (isLoading) {
		return <MySpinner />;
	}

	if (!isAuthenticated) {
		return (
			<Navigate
				to={errorPath}
				replace
				state={{ error: "Please complete the login process." }}
			/>
		);
	}

	return <MainLayout>{children || <Outlet />}</MainLayout>;
};

export default ProtectedRoute;
