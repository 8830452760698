import useStore from "@/store"
import type {
  GenericResponse,
  ICard,
  ICardResponse,
  ICardsResponse, IDailiesResponse, IDaily,
  IDailyUpdate,
  ILoginResponse,
  IPromoCode,
  IPromoCodesResponse,
  IStat,
  ITask,
  ITaskResponse,
  ITasksResponse,
  IUpdatePromoCodeRequest,
} from "@/types"
import axios, { AxiosResponse } from "axios"

const BASE_URL = `${ import.meta.env.VITE_ADMIN_BACK }`

export const API = axios.create( {
  baseURL: BASE_URL,
  withCredentials: true,
} )

API.defaults.headers.common[ "Content-Type" ] = "application/json"

API.interceptors.request.use( ( config ) => {
  const { auth_token } = useStore.getState()
  if ( auth_token ) {
    config.headers.Authorization = `Bearer ${ auth_token }`
  }
  return config
} )

export const createCard = async ( formData: FormData ): Promise<ICard> => {
  const response = await API.post( "/admin/card", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  } )
  return response.data
}

export const createPromoCode = async ( formData: FormData ): Promise<IPromoCode> => {
  const response = await API.post( "/admin/promo_code", formData )
  return response.data
}


// export const updateCard = async ( cardId: string, card: FormData ) => {
//   const response = await API.patch<ICardResponse>(
//     `/admin/cards/${ cardId }`,
//     card,
//   )
//   return response.data
// }

export const updateCard = async ( cardId: string, card: any ) => {
  const response = await API.patch<ICardResponse>(
    `/admin/cards/${ cardId }`,
    card,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  return response.data
}

export const deleteCard = async ( cardId: string ) => {
  return API.delete<null>( `/admin/cards/${ cardId }` )
}

export const getSingleCard = async ( cardId: string ) => {
  const response = await API.get<ICardResponse>( `/admin/cards/${ cardId }` )
  return response.data
}

export const getCards = async () => {
  const cards = await API.get<ICardsResponse>( "/admin/cards" )
  return cards.data
}

export const login = async ( username: string, password: string ) => {
  const response = await API.post<ILoginResponse>( "/login", {
    username,
    password,
  } )
  return response.data
}

export const logout = async () => {
  const response = await API.post<GenericResponse>( "/logout" )
  return response.data
}

export const uploadImageToYandex = async ( file: File ): Promise<string> => {
  const formData = new FormData()
  formData.append( "file", file )

  const response = await API.post( "/admin/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  } )

  return response.data[ 0 ]
}

export const getPromoCodes = async (): Promise<IPromoCodesResponse> => {
  const response = await API.get( "/admin/promo_codes" )
  return response.data
}

export const updatePromoCode = async (
  id: string,
  data: IUpdatePromoCodeRequest,
): Promise<IPromoCode> => {
  const response = await API.patch( `/admin/promo_code/${ id }`, data )
  return response.data
}

export const deletePromoCode = async ( id: string ) => {
  return API.delete<null>( `/admin/promo_code/${ id }` )
}

export const createTask = async ( formData: FormData ): Promise<ITask> => {
  const response = await API.post( "/admin/task", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  } )
  return response.data
}

export const updateTask = async ( taskId: string, task: FormData ) => {
  const response = await API.patch<ITaskResponse>( `/admin/task/${ taskId }`, task )
  return response.data
}

export const deleteTask = async ( taskId: string ) => {
  return API.delete<null>( `/admin/task/${ taskId }` )
}

export const getTasks = async () => {
  const response = await API.get<ITasksResponse>( "/admin/tasks" )
  //@ts-ignore
  return response.data
}

export const createDaily = async ( data: IDaily ): Promise<IDaily> => {
  if ( !data._id ) {
    data._id = "temp_daily_id"
  }
  const response = await API.post( "/admin/daily", data )
  return response.data
}

export const getDailies = async () => {
  const dailies = await API.get<IDailiesResponse>( "/admin/dailies" )
  return dailies.data
}

export const updateDaily = async ( daily: IDailyUpdate ): Promise<IDailyUpdate> => {
  const response = await API.patch<IDailyUpdate>( `/admin/daily`, daily )
  return response.data
}

export const deleteDaily = async ( daily_id: string ): Promise<AxiosResponse<void>> => {
  return API.delete<void>( '/admin/daily', {
    data: { _id: daily_id }
  } )
}

export const getStats = async () => {
  const response = await API.get<IStat>( "/admin/players_stats" )
  return response.data
}
