import FloatingButton from "@/components/FloatingButton"
import CreateCardForm from "@/components/card/CreateCardForm"
import PromoCodeForm from "@/components/promocode/CreatePromoCodeForm"
import CreateTaskForm from "@/components/task/CreateTaskForm"
import CreateDailyForm from "@/components/daily/CreateDailyForm"
import type React from "react"
import { useState } from "react"
import Modal from "react-bootstrap/Modal"

const AdminPage: React.FC = () => {
  const [ showCardModal, setShowCardModal ] = useState( false )
  const [ showPromoModal, setShowPromoModal ] = useState( false )
  const [ showTaskModal, setShowTaskModal ] = useState( false )
  const [ showDailyModal, setShowDailyModal ] = useState( false )

  const handleShowCard = () => setShowCardModal( true )
  const handleCloseCard = () => setShowCardModal( false )

  const handleShowPromo = () => setShowPromoModal( true )
  const handleClosePromo = () => setShowPromoModal( false )

  const handleShowTask = () => setShowTaskModal( true )
  const handleCloseTask = () => setShowTaskModal( false )

  const handleShowDaily = () => setShowDailyModal( true )
  const handleCloseDaily = () => setShowDailyModal( false )

  return (
    <>
      <FloatingButton
        onCardClick={ handleShowCard }
        onPromoClick={ handleShowPromo }
        onTaskClick={ handleShowTask }
        onQuestionClick={ handleShowDaily }
      />

      <Modal show={ showCardModal } onHide={ handleCloseCard }>
        <Modal.Header closeButton>
          <Modal.Title>Создать карту</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateCardForm handleClose={ handleCloseCard } />
        </Modal.Body>
      </Modal>

      <Modal show={ showPromoModal } onHide={ handleClosePromo }>
        <Modal.Header closeButton>
          <Modal.Title>Создать промокод</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PromoCodeForm handleClose={ handleClosePromo } />
        </Modal.Body>
      </Modal>

      <Modal show={ showTaskModal } onHide={ handleCloseTask }>
        <Modal.Header closeButton>
          <Modal.Title>Создать задание</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateTaskForm handleClose={ handleCloseTask } />
        </Modal.Body>
      </Modal>

      <Modal show={ showDailyModal } onHide={ handleCloseDaily }>
        <Modal.Header closeButton>
          <Modal.Title>Создать вопрос</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* @ts-ignore */}
          <CreateDailyForm handleClose={ handleCloseDaily } />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AdminPage
