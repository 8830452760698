import type React from "react"
import { useState } from "react"
import { useFormContext } from "react-hook-form"
import { Form } from "react-bootstrap"
import { toast } from "react-toastify"

interface ImageUploadProps {
  onImageUpload: ( file: File ) => void
}

const ImageUpload: React.FC<ImageUploadProps> = ( { onImageUpload } ) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext()
  const [ uploading, setUploading ] = useState<boolean>( false )

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files
    if ( files && files.length > 0 ) {
      const file = files[ 0 ]
      setValue( "image", file )
      onImageUpload( file )
    }
  }

  return (
    <Form.Group controlId="image" className="mb-4">
      { uploading && <Form.Text>Идет загрузка...</Form.Text> }
      <Form.Control
        type="file"
        accept="image/*"
        onChange={ handleFileChange }
        disabled={ uploading }
        isInvalid={ !!errors[ "image" ]?.message }
      />
      <Form.Control.Feedback type="invalid" style={ { textAlign: "right" } }>
        { errors[ "image" ]?.message as string }
      </Form.Control.Feedback>
    </Form.Group >
  )
}

export default ImageUpload
