import React from "react"
import { Button, Col, Row, Alert } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import { useForm, FormProvider, useFieldArray, SubmitHandler } from "react-hook-form"
import { toast } from "react-toastify"
import { zodResolver } from "@hookform/resolvers/zod"
import { createDaily as createDailyApi } from "@/api"
import FormInput from "@/components/FormInput"
import { create_daily_schema } from "@/schemas"
import useStore from "@/store"
import { TypeOf } from "zod"
import { LoadingButton } from "../LoadingButton"

export type dailyInput = TypeOf<typeof create_daily_schema>

interface CreateDailyFormProps {
  handleClose: () => void
  date: Date | null
}

const CreateDailyForm: React.FC<CreateDailyFormProps> = ( { handleClose, date } ) => {
  const methods = useForm<dailyInput>( {
    resolver: zodResolver( create_daily_schema ),
    defaultValues: {
      question: "",
      answers: [
        { value: "", is_correct: false },
        { value: "", is_correct: false },
        { value: "", is_correct: false },
      ],
      date: date ? date.toISOString().split( "T" )[ 0 ] : "",
      // @ts-ignore
      reward: "",
    },
  } )

  const { fields, append, remove } = useFieldArray( {
    control: methods.control,
    name: "answers",
  } )

  const { fetchDailies, setRequestLoading, requestLoading, setActiveTabKey } = useStore()

  const createDaily: SubmitHandler<dailyInput> = async ( data ) => {
    const transformedData = {
      _id: "temp_daily_id",
      date: new Date( data.date ).toISOString(),
      question: {
        ru: data.question,
      },
      answers: data.answers.map( ( answer ) => ( {
        is_correct: answer.is_correct,
        value: {
          ru: answer.value,
        },
      } ) ),
      reward: data.reward,
    }

    try {
      setRequestLoading( true )

      await toast.promise( createDailyApi( transformedData ), {
        pending: "Создание вопроса. Подождите...",
        success: "Вопрос успешно создан",
        error: "Ошибка при создании вопроса",
      } )

      await fetchDailies()
      methods.reset()
      handleClose()
      setActiveTabKey( "dailies" )
    } catch ( error ) {
      console.error( "Error creating daily:", error )
      toast.error( "Произошла ошибка при создании вопроса" )
    } finally {
      setRequestLoading( false )
    }
  }

  const handleCorrectAnswerChange = ( index: number ) => {
    const updatedAnswers = methods.getValues( "answers" ).map( ( answer, i ) => ( {
      ...answer,
      is_correct: i === index,
    } ) )
    methods.setValue( "answers", updatedAnswers )
  }

  return (
    <FormProvider { ...methods }>
      <Form onSubmit={ methods.handleSubmit( createDaily ) }>
        <Row>
          <Col xs={ 12 }>
            <FormInput label="Вопрос" name="question" />
            { methods.formState.errors.question && (
              <Alert variant="danger">
                { methods.formState.errors.question.message }
              </Alert>
            ) }
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 } md={ 6 }>
            <FormInput label="Вознаграждение" name="reward" />
            { methods.formState.errors.reward && (
              <Alert variant="danger">
                { methods.formState.errors.reward.message }
              </Alert>
            ) }
          </Col>
          <Col xs={ 12 } md={ 6 }>
            <FormInput label="Дата" name="date" type="date" />
            { methods.formState.errors.date && (
              <Alert variant="danger">
                { methods.formState.errors.date.message }
              </Alert>
            ) }
          </Col>
        </Row>
        <hr />
        { fields.map( ( field, index ) => (
          <React.Fragment key={ field.id }>
            <Row className="mb-2">
              <Col xs={ 12 } md={ 8 }>
                <FormInput label={ `Ответ ${ index + 1 }` } name={ `answers.${ index }.value` } />
                { methods.formState.errors.answers?.[ index ]?.value && (
                  <Alert variant="danger">
                    { methods.formState.errors.answers[ index ].value.message }
                  </Alert>
                ) }
              </Col>
              <Col xs={ 12 } md={ 2 }>
                <Form.Group controlId={ `answers.${ index }.is_correct` }>
                  <Form.Check
                    type="checkbox"
                    label="Правильный"
                    { ...methods.register( `answers.${ index }.is_correct` ) }
                    isInvalid={ !!methods.formState.errors.answers?.[ index ]?.is_correct }
                    onChange={ () => handleCorrectAnswerChange( index ) }
                  />
                  { methods.formState.errors.answers?.[ index ]?.is_correct && (
                    <Form.Control.Feedback type="invalid">
                      { methods.formState.errors.answers[ index ].is_correct.message }
                    </Form.Control.Feedback>
                  ) }
                </Form.Group>
              </Col>
              <Col xs={ 12 } md={ 2 }>
                <Button variant="danger" onClick={ () => remove( index ) } className="w-100">
                  Удалить
                </Button>
              </Col>
            </Row>
            <hr />
          </React.Fragment>
        ) ) }
        { methods.formState.errors.root && (
          <Row>
            <Col xs={ 12 }>
              <Alert variant="danger">
                { methods.formState.errors.root.message }
              </Alert>
            </Col>
          </Row>
        ) }
        <Row>
          <LoadingButton
            loading={ requestLoading }
            btnColor={
              Object.keys( methods.formState.touchedFields ).length > 0 &&
                Object.keys( methods.formState.errors ).length === 0
                ? "success"
                : "secondary"
            }
            /* @ts-ignore */
            disabled={ requestLoading || !methods.formState.isValid }
          >
            Добавить ежедневный вопрос в игру
          </LoadingButton>
        </Row>
      </Form>
    </FormProvider>
  )
}

export default CreateDailyForm
