import * as z from "zod"
import { object, string } from "zod"
import { languages } from "./languages"

// TODO: rewrite schemas
export const create_card_schema = object( {
  name: string().min( 1, "Название карты обязательно" ),
  description: string().min( 1, "Описание карты обязательно" ),
  card_type: string().min( 1, "Тип карты обязателен" ),
  base_price: string()
    .min( 1, "Базовая цена обязательна" )
    .transform( ( val ) => Number( val ) )
    .refine( ( val ) => !isNaN( val ), { message: "Базовая цена должна быть числом" } )
    .refine( ( val ) => val > 0, { message: "Базовая цена должна быть положительной" } ),
  price_increment:
    string()
      .min( 1, "Увеличение цены за уровень обязательно" )
      .transform( ( val ) => Number( val ) )
      .refine( ( val ) => !isNaN( val ), { message: "Увеличение цены за уровень должно быть числом" } )
      .refine( ( val ) => val >= 0, { message: "Увеличение цены за уровень не может быть отрицательным" } ),
  income_percentage:
    string()
      .min( 1, "Доход от стоимости уровня карты обязателен" )
      .transform( ( val ) => Number( val ) )
      .refine( ( val ) => !isNaN( val ), { message: "Доход от стоимости уровня карты должен быть числом" } )
      .refine( ( val ) => val >= 0, { message: "Доход от стоимости уровня карты не может быть отрицательным" } ),
  image: z.instanceof( File, { message: "Картинка обязательна" } ),
} )

const nameSchema = languages.reduce( ( acc, lang ) => {
  acc[ `name.${ lang.code }` ] = string().optional()
  return acc
}, {} )

const descriptionSchema = languages.reduce( ( acc, lang ) => {
  acc[ `description.${ lang.code }` ] = string().optional()
  return acc
}, {} )

export const edit_card_schema = object( {
  ...nameSchema,
  ...descriptionSchema,
  card_type: string().min( 1, "Тип карты обязателен" ),
  base_price: string()
    .min( 1, "Базовая цена обязательна" )
    .transform( ( val ) => Number( val ) )
    .refine( ( val ) => !isNaN( val ), { message: "Базовая цена должна быть числом" } )
    .refine( ( val ) => val > 0, { message: "Базовая цена должна быть положительной" } ),
  price_increment: string()
    .min( 1, "Увеличение цены за уровень обязательно" )
    .transform( ( val ) => Number( val ) )
    .refine( ( val ) => !isNaN( val ), { message: "Увеличение цены за уровень должно быть числом" } )
    .refine( ( val ) => val >= 0, { message: "Увеличение цены за уровень не может быть отрицательным" } ),
  base_income: string()
    .min( 1, "Базовый доход обязателен" )
    .transform( ( val ) => Number( val ) )
    .refine( ( val ) => !isNaN( val ), { message: "Базовый доход должен быть числом" } )
    .refine( ( val ) => val >= 0, { message: "Базовый доход не может быть отрицательным" } ),
  income_increment: string()
    .min( 1, "Увеличение дохода за уровень обязательно" )
    .transform( ( val ) => Number( val ) )
    .refine( ( val ) => !isNaN( val ), { message: "Увеличение дохода за уровень должно быть числом" } )
    .refine( ( val ) => val >= 0, { message: "Увеличение дохода за уровень не может быть отрицательным" } ),
  fake_income: string()
    .min( 1, "Это обязательно" )
    .transform( ( val ) => Number( val ) )
    .refine( ( val ) => !isNaN( val ), { message: "Это должно быть числом" } )
    .refine( ( val ) => val >= 0, { message: "Это не может быть отрицательным" } ),
  image: z.instanceof( File, { message: "Картинка обязательна" } ).optional(),
} )

export const create_task_schema = object( {
  title: string().min( 1, "Заголовок обязателен" ),
  url: string().min( 1, "Ссылка обязательна" ),
  bonus: string()
    .min( 1, "Вознаграждение обязательно" )
    .transform( ( val ) => Number( val ) )
    .refine( ( val ) => !isNaN( val ), { message: "Вознаграждение должно быть числом" } )
    .refine( ( val ) => val > 0, { message: "Вознаграждение должно быть положительной" } ),
  image: z.instanceof( File, { message: "Картинка обязательна" } ),
} )

export const edit_task_schema = object( {
  title: string().min( 1, "Заголовок обязателен" ),
  url: string().min( 1, "Ссылка обязательна" ),
  bonus: string()
    .min( 1, "Вознаграждение обязательно" )
    .transform( ( val ) => Number( val ) )
    .refine( ( val ) => !isNaN( val ), { message: "Вознаграждение должно быть числом" } )
    .refine( ( val ) => val > 0, { message: "Вознаграждение должно быть положительной" } ),
  image: z.instanceof( File, { message: "Картинка обязательна" } ).optional(),
} )



export const create_promo_schema = z.object( {
  code: z.string().min( 1, "Промокод обязателен" ),
  bonus: z.string()
    .min( 1, "Бонус обязателен" )
    .transform( ( val ) => Number( val ) )
    .refine( ( val ) => !isNaN( val ), { message: "Бонус должен быть числом" } )
    .refine( ( val ) => val > 0, { message: "Бонус должен быть положительным" } ),
  usage_limit: z.string()
    .optional()
    .transform( ( val ) => val === '' ? null : Number( val ) )
    .refine( ( val ) => val === null || val >= 0, { message: "Лимит использования не может быть отрицательным" } ),
  disableUsageLimit: z.boolean()
} ).superRefine( ( { usage_limit, disableUsageLimit }, ctx ) => {
  if ( !disableUsageLimit && ( usage_limit === null || usage_limit === undefined ) ) {
    ctx.addIssue( {
      code: z.ZodIssueCode.custom,
      message: "Введите лимит использования или выберите 'Без лимита использования'",
      path: [ 'usage_limit' ],
    } )
  }
} )

export const edit_promo_schema = object( {
  code: string().min( 1, "Промокод обязателен" ),
  bonus: string()
    .min( 1, "Бонус обязателен" )
    .transform( ( val ) => Number( val ) )
    .refine( ( val ) => !isNaN( val ), { message: "Бонус должен быть числом" } )
    .refine( ( val ) => val > 0, { message: "Бонус должен быть положительным" } ),
  usage_limit: string()
    .transform( ( val ) => Number( val ) )
    .nullable()
    .refine( ( val ) => val === null || val >= 0, "Лимит использования должен быть положительным числом или null" ),
  valid: z
    .string()
    .transform( ( val ) => val === "true" )
    .refine( ( val ) => typeof val === "boolean", { message: "Статус должен быть булевым значением" } ),
} )

export const create_daily_schema = z.object( {
  question: z.string().min( 1, "Вопрос обязателен" ),
  reward: z.string()
    .min( 1, "Вознаграждение обязателено" )
    .transform( ( val ) => Number( val ) )
    .refine( ( val ) => !isNaN( val ), { message: "Вознаграждение должно быть числом" } )
    .refine( ( val ) => val > 0, { message: "Вознаграждение должно быть положительным числом" } ),
  answers: z.array(
    z.object( {
      value: z.string().min( 1, "Ответ обязателен" ),
      is_correct: z.boolean(),
    } )
  ).min( 3, "Должно быть как минимум три ответа" )
    .refine( answers => answers.some( answer => answer.is_correct ), {
      message: "Хотя бы один ответ должен быть правильным",
    } ),
  date: z.string().nullable(),
} )

export const edit_daily_schema = object( {
  question: z.string().min( 1, "Вопрос обязателен" ),
  reward: z.string()
    .min( 1, "Вознаграждение обязателено" )
    .transform( ( val ) => Number( val ) )
    .refine( ( val ) => !isNaN( val ), { message: "Вознаграждение должно быть числом" } )
    .refine( ( val ) => val > 0, { message: "Вознаграждение должно быть положительным числом" } ),
  answers: z.array(
    z.object( {
      value: z.string().min( 1, "Ответ обязателен" ),
      is_correct: z.boolean(),
    } )
  ).min( 3, "Должно быть как минимум три ответа" )
    .refine( answers => answers.some( answer => answer.is_correct ), {
      message: "Хотя бы один ответ должен быть правильным",
    } ),
  date: z.string().nullable().optional(),
} )

