import { login } from "@/api"; // Импорт функции логина
import useStore from "@/store";
import FormInput from "@components/FormInput";
import { LoadingButton } from "@components/LoadingButton";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import type { TypeOf } from "zod";
import { object, string } from "zod";

export const loginSchema = object({
	username: string().min(1, "Введите имя пользователя"),
	password: string()
		.min(1, "Введите пароль")
		.min(8, "Пароль должен состоять минимум из 8 символов")
		.max(32, "Пароль должен состоять максимум из 32 символов"),
});

export type LoginInput = TypeOf<typeof loginSchema>;

const LoginPage = () => {
	const store = useStore();
	const navigate = useNavigate();

	const methods = useForm<LoginInput>({
		resolver: zodResolver(loginSchema),
	});

	const {
		reset,
		handleSubmit,
		formState: { isSubmitSuccessful },
	} = methods;

	useEffect(() => {
		if (isSubmitSuccessful) {
			reset();
		}
	}, [isSubmitSuccessful]);

	const loginAdmin = async (data: LoginInput) => {
		try {
			store.setRequestLoading(true);
			const result = await login(data.username, data.password);

			if (result) {
				store.setAuthToken(result.auth_token);
				toast.success("Вы вошли в систему");
				navigate("/admin");
			} else {
				toast.error("Отказано в доступе");
			}

			store.setRequestLoading(false);
		} catch (error: any) {
			store.setRequestLoading(false);
			const resMessage =
				error.response?.data?.message || error.message || error.toString();
			toast.error(resMessage);
		}
	};

	const onSubmitHandler: SubmitHandler<LoginInput> = (values) => {
		loginAdmin(values);
	};

	return (
		<section className="mt-auto">
			<div className="container">
				<h4>Приветствую! Давайте начнём</h4>
				<h6 className="fw-light">Войдите, чтобы продолжить.</h6>
				<FormProvider {...methods}>
					<Form className="pt-3" onSubmit={handleSubmit(onSubmitHandler)}>
						<div className="form-group">
							<FormInput label="Введите имя пользователя" name="username" />
						</div>
						<div className="form-group">
							<FormInput label="Пароль" name="password" type="password" />
						</div>

						<div className="mt-3">
							<LoadingButton loading={store.requestLoading} bigSize>
								Войти
							</LoadingButton>
						</div>

						<div className="my-2 d-flex justify-content-between align-items-center">
							<div className="form-check">
								<label className="form-check-label text-muted">
									<input type="checkbox" className="form-check-input" />
									Не выходить из системы
								</label>
							</div>
							<Link to="" className="auth-link text-black">
								Забыли пароль?
							</Link>
						</div>
						<div className="text-center mt-4 fw-light">
							{"Нет личного кабинета? "}
							<Link to="/">И не будет!</Link>
						</div>
					</Form>
				</FormProvider>
			</div>
		</section>
	);
};

export default LoginPage;
