import { Link, useNavigate, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import { lazy, Suspense, useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import useStore from "@/store"
import { logout } from "@/api"
import MySpinner from "@/components/Spinner"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import Fade from "react-bootstrap/Fade"

const CardList = lazy( () => import( "@/components/card/CardsList" ) )
const PromoCodeList = lazy( () => import( "@/components/promocode/PromoCodeList" ) )
const TaskList = lazy( () => import( "@/components/task/TaskList" ) )
const DailiesList = lazy( () => import( "@/components/daily/DailiesList" ) )
const StatsList = lazy( () => import( "@/components/stats/StatsList" ) )

const Header = () => {
  const { resetStore, setRequestLoading, setAuthToken, auth_token, activeTabKey, setActiveTabKey, fetchStats, fetchCards, fetchPromoCodes, fetchTasks, fetchDailies } = useStore()
  const navigate = useNavigate()
  const location = useLocation()
  const [ isLoading, setIsLoading ] = useState( false )

  const logoutAdmin = async () => {
    try {
      setRequestLoading( true )
      await toast.promise( logout(), {
        pending: "Очистка данных. Подождите...",
        success: "Вы вышли из системы",
        error: "Ошибка при выходе",
      } )

      resetStore()
      localStorage.removeItem( "umperium-admin-store" )

      setRequestLoading( false )
      navigate( "/" )
    } catch ( error ) {
      setRequestLoading( false )
      navigate( "/" )
    }
  }

  useEffect( () => {
    if ( !auth_token && location.pathname.startsWith( "/admin" ) ) {
      navigate( "/login" )
    }
  }, [ auth_token, navigate, location.pathname ] )

  const handleSelect = async ( k: string | null ) => {
    if ( k ) {
      setActiveTabKey( k )

      if ( k === "cards" ) {
        await fetchCards()
      } else if ( k === "promoCodes" ) {
        await fetchPromoCodes()
      } else if ( k === "tasks" ) {
        await fetchTasks()
      } else if ( k === "dailies" ) {
        await fetchDailies()
      } else if ( k === "stats" ) {
        await fetchStats()
      }
    }
  }

  useEffect( () => {
    handleSelect( activeTabKey )
  }, [] )

  if ( isLoading ) {
    return <MySpinner />
  }

  return (
    <>
      <div
        className={ `d-flex flex-column flex-md-row align-items-center justify-content-between ${ auth_token ? "" : "pb-5 mb-5 border-bottom" }` }
      >
        <Link
          to="/admin"
          className={ `d-flex align-items-center text-dark text-decoration-none ${ auth_token ? "" : "mx-auto" }` }
        >
          <h1 style={ { color: "black", fontWeight: "bold" } }>Umperium</h1>
          <h1 style={ { color: "#4018ac", fontWeight: "bold" } }>Admin</h1>
        </Link>
        { auth_token && location.pathname.startsWith( "/admin" ) && (
          <Button
            onClick={ logoutAdmin }
            variant="outline-danger"
            className="mb-3"
          >
            Выйти
          </Button>
        ) }
      </div>
      { auth_token && location.pathname.startsWith( "/admin" ) && (
        <Tabs
          id="admin-tabs"
          activeKey={ activeTabKey }
          onSelect={ handleSelect }
          className="mb-5 justify-content-center"
          transition={ Fade }
        >
          <Tab eventKey="cards" title="Карты">
            <Suspense fallback={ <MySpinner /> }>
              <CardList />
            </Suspense>
          </Tab>
          <Tab eventKey="promoCodes" title="Промокоды">
            <Suspense fallback={ <MySpinner /> }>
              <PromoCodeList />
            </Suspense>
          </Tab>
          <Tab eventKey="tasks" title="Задания">
            <Suspense fallback={ <MySpinner /> }>
              <TaskList />
            </Suspense>
          </Tab>
          <Tab eventKey="dailies" title="Вопросы">
            <Suspense fallback={ <MySpinner /> }>
              <DailiesList />
            </Suspense>
          </Tab>
          <Tab eventKey="stats" title="Статистика">
            <Suspense fallback={ <MySpinner /> }>
              <StatsList />
            </Suspense>
          </Tab>
        </Tabs>
      ) }
    </>
  )
}

export default Header
