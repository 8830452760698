export const languages = [
  { code: "en", label: "Английский" },
  { code: "ru", label: "Русский" },
  { code: "de", label: "Немецкий" },
  { code: "es", label: "Испанский" },
  { code: "fr", label: "Французский" },
  { code: "hi", label: "Хинди" },
  { code: "id", label: "Индонезийский" },
  { code: "pt", label: "Португальский" },
  { code: "th", label: "Тайский" },
  { code: "tr", label: "Турецкий" },
  { code: "uz", label: "Узбекский" },
  { code: "vi", label: "Вьетнамский" }
]
