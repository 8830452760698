import ProtectedRoute from "@components/ProtectedRoute"
import AdminPage from "@pages/AdminPage"
import ErrorPage from "@pages/ErrorPage"
import LoginPage from "@pages/LoginPage"
import { createBrowserRouter } from "react-router-dom"
import MainLayout from "@/Layout"

const routes = createBrowserRouter( [
  {
    path: "/",
    element: (
      <MainLayout>
        <LoginPage />
      </MainLayout>
    ),
  },
  {
    path: "/admin",
    element: (
      <ProtectedRoute>
        <AdminPage />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "*",
    element: (
      <MainLayout>
        <ErrorPage />
      </MainLayout>
    ),
  },
] )

export default routes
