import { createCard as createCardApi } from "@/api"
import FormInput from "@/components/FormInput"
import ImageUpload from "@/components/ImageUpload"
import { create_card_schema } from "@/schemas"
import useStore from "@/store"
import { zodResolver } from "@hookform/resolvers/zod"
import { useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { toast } from "react-toastify"
import { TypeOf } from "zod"
import { LoadingButton } from "../LoadingButton"
import PreviewCardModal from "./PreviewCardModal"

export type cardInput = TypeOf<typeof create_card_schema>

interface CardFormProps {
  handleClose: () => void
}

const CreateCardForm: React.FC<CardFormProps> = ( { handleClose } ) => {
  const methods = useForm<cardInput>( {
    resolver: zodResolver( create_card_schema ),
  } )
  const { fetchCards, setRequestLoading, requestLoading, setActiveTabKey } = useStore()
  const [ file, setFile ] = useState<File | null>( null )
  const [ showPreview, setShowPreview ] = useState( false )

  const {
    formState: { errors, touchedFields },
  } = methods

  const createCard: SubmitHandler<cardInput> = async ( data ) => {
    try {
      setRequestLoading( true )

      const formData = new FormData()

      const formattedData = {
        ...data,
        name: { ru: data.name },
        description: { ru: data.description },
      }

      Object.entries( formattedData ).forEach( ( [ key, value ] ) => {
        if ( key !== "image" ) {
          formData.append( key, typeof value === 'object' ? JSON.stringify( value ) : value as string | Blob )
        }
      } )

      if ( file ) {
        formData.append( "image", file )
      }

      await toast.promise( createCardApi( formData ), {
        pending: "Создание карты. Подождите...",
        success: "Карта успешно создана",
        error: "Ошибка при создании карты",
      } )

      await fetchCards()

      setRequestLoading( false )
      methods.reset()
      setFile( null )
      handleClose()
      setActiveTabKey( 'cards' )
    } catch ( error ) {
      console.error( "Error creating card:", error )
      toast.error( "Произошла ошибка при создании карты" )
    } finally {
      setRequestLoading( false )
    }
  }

  const handlePreview = () => {
    setShowPreview( true )
  }

  return (
    <>
      <FormProvider { ...methods }>
        <Form onSubmit={ methods.handleSubmit( createCard ) }>
          <ImageUpload onImageUpload={ setFile } />
          <Row>
            <Col xs={ 12 } md={ 6 }>
              <FormInput label="Название карты" name="name" />
            </Col>
            <Col xs={ 12 } md={ 6 }>
              <FormInput name="card_type" type="select" as="select">
                <option value="" disabled>Тип карты</option>
                <option value="direction">Сфера</option>
                <option value="round">Раунд</option>
                <option value="project">Проект</option>
                <option value="special">Специальная</option>
              </FormInput>
            </Col>
          </Row>
          <FormInput
            label="Описание карты"
            name="description"
            type="textarea"
            as="textarea"
            rows={ 10 }
          />
          <Row>
            <Col xs={ 12 } md={ 6 }>
              <FormInput label="Начальная стоимость" name="base_price" />
            </Col>
            <Col xs={ 12 } md={ 6 }>
              <FormInput label="Увеличение стоимости за уровень (%)" name="price_increment" />
            </Col>
          </Row>
          <Row>
            <Col xs={ 12 } md={ 6 }>
              <FormInput label="Доход от стоимости уровня карты (%)" name="income_percentage" />
            </Col>
          </Row>


          <Row>
            <Button variant="outline-info" onClick={ handlePreview } className="ml-2 mb-2">
              Предварительные расчёты
            </Button>
          </Row>
          <Row>
            <LoadingButton
              loading={ requestLoading }
              btnColor={
                Object.keys( touchedFields ).length > 0 &&
                  Object.keys( errors ).length === 0
                  ? "success"
                  : "secondary"
              }
            >
              Добавить карту в игру
            </LoadingButton>
          </Row>
        </Form>
      </FormProvider>
      { showPreview && (
        <PreviewCardModal
          show={ showPreview }
          handleClose={ () => setShowPreview( false ) }
          cardData={ methods.getValues() }
        />
      ) }
    </>
  )
}

export default CreateCardForm
