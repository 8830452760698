import React from "react"
import ReactDOM from "react-dom/client"
import { RouterProvider } from "react-router-dom"

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "react-toastify/dist/ReactToastify.css"

import router from "@/routes"
import { AuthProvider } from "@components/AuthContext"

import { ToastContainer } from "react-toastify"

import "./index.css"

const isDev = import.meta.env.VITE_NODE_ENV === "dev"

const authUrl = isDev
  ? import.meta.env.VITE_DEV_AUTH_URL
  : import.meta.env.VITE_PROD_AUTH_URL

const container = document.getElementById( "root" )
const root = ReactDOM.createRoot( container! )

root.render(
  <>
    <AuthProvider authUrl={ authUrl || "" }>
      <RouterProvider router={ router } />
    </AuthProvider>
    <ToastContainer position="bottom-left" closeOnClick />
  </>,
)
