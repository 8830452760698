import type React from "react"
import { useState, useEffect, useRef } from "react"
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlus,
  faCreditCard,
  faTags,
  faTasks,
  faQuestionCircle
} from "@fortawesome/free-solid-svg-icons"

interface FloatingButtonProps {
  onCardClick: () => void
  onPromoClick: () => void
  onTaskClick: () => void
  onQuestionClick: () => void
}

const FloatingButton: React.FC<FloatingButtonProps> = ( {
  onCardClick,
  onPromoClick,
  onTaskClick,
  onQuestionClick,
} ) => {
  const [ isClicked, setIsClicked ] = useState( false )
  const containerRef = useRef<HTMLDivElement>( null )

  useEffect( () => {
    const handleClickOutside = ( event: MouseEvent ) => {
      if (
        containerRef.current &&
        !containerRef.current.contains( event.target as Node )
      ) {
        setIsClicked( false )
      }
    }

    document.addEventListener( "mousedown", handleClickOutside )
    return () => {
      document.removeEventListener( "mousedown", handleClickOutside )
    }
  }, [] )

  return (
    <div className="floating-button-container" ref={ containerRef }>
      <OverlayTrigger
        placement="left"
        overlay={ <Tooltip id="floating-button-tooltip">Создать</Tooltip> }
      >
        <Button
          className={ `floating-button ${ isClicked ? "hidden" : "visible" }` }
          variant="primary"
          onClick={ () => setIsClicked( true ) }
        >
          <FontAwesomeIcon icon={ faPlus } />
        </Button>
      </OverlayTrigger>
      <div className={ `floating-options ${ isClicked ? "visible" : "hidden" }` }>
        <OverlayTrigger
          placement="left"
          overlay={ <Tooltip id="card-button-tooltip">Создать карту</Tooltip> }
        >
          <Button
            onClick={ onCardClick }
            variant="success"
            className="floating-option"
          >
            <FontAwesomeIcon icon={ faCreditCard } />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="left"
          overlay={ <Tooltip id="promo-button-tooltip">Создать промокод</Tooltip> }
        >
          <Button
            onClick={ onPromoClick }
            variant="warning"
            className="floating-option"
          >
            <FontAwesomeIcon icon={ faTags } />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="left"
          overlay={ <Tooltip id="task-button-tooltip">Создать задачу</Tooltip> }
        >
          <Button onClick={ onTaskClick } variant="info" className="floating-option">
            <FontAwesomeIcon icon={ faTasks } />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="left"
          overlay={ <Tooltip id="question-button-tooltip">Создать вопрос</Tooltip> }
        >
          <Button onClick={ onQuestionClick } variant="primary" className="floating-option">
            <FontAwesomeIcon icon={ faQuestionCircle } />
          </Button>
        </OverlayTrigger>
      </div>
    </div>
  )
}

export default FloatingButton
