import { create } from "zustand"
import { persist, devtools } from "zustand/middleware"
import type { StateCreator } from "zustand"
import type { ICard, IDaily, IPromoCode, IStat, ITask } from "./types"
import { getCards, getDailies, getPromoCodes, getTasks, getStats } from "./api"

export interface MainStore {
  auth_token: string | null
  requestLoading: boolean
  cards: ICard[]
  tasks: ITask[]
  stats: IStat[]
  dailies: IDaily[]
  promoCodes: IPromoCode[]
  activeTabKey: string
  currentPage: number
  itemsPerPage: number
  setAuthToken: ( auth_token: string | null ) => void
  setRequestLoading: ( isLoading: boolean ) => void
  setCards: ( cards: ICard[] ) => void
  setTasks: ( tasks: ITask[] ) => void
  setStats: ( stats: IStat[] ) => void
  setPromoCodes: ( promoCodes: IPromoCode[] ) => void
  setActiveTabKey: ( key: string ) => void
  setCurrentPage: ( page: number ) => void
  setItemsPerPage: ( itemsPerPage: number ) => void
  resetStore: () => void
  fetchCards: () => Promise<void>
  fetchTasks: () => Promise<void>
  fetchPromoCodes: () => Promise<void>
  fetchDailies: () => Promise<void>
  fetchStats: () => Promise<void>
}

export const useMainStore: StateCreator<MainStore, [], [], MainStore> = ( set ) => ( {
  auth_token: null,
  requestLoading: false,
  cards: [],
  tasks: [],
  stats: [],
  dailies: [],
  promoCodes: [],
  activeTabKey: "cards",
  currentPage: 1,
  itemsPerPage: 50,
  setAuthToken: ( auth_token ) => set( { auth_token } ),
  setRequestLoading: ( isLoading ) => set( { requestLoading: isLoading } ),
  setCards: ( cards ) => set( { cards } ),
  setTasks: ( tasks ) => set( { tasks } ),
  setStats: ( stats ) => set( { stats } ),
  setPromoCodes: ( promoCodes ) => set( { promoCodes } ),
  setActiveTabKey: ( key ) => set( { activeTabKey: key } ),
  setCurrentPage: ( page ) => set( { currentPage: page } ),
  setItemsPerPage: ( itemsPerPage ) => set( { itemsPerPage } ),
  resetStore: () =>
    set( {
      auth_token: null,
      requestLoading: false,
      cards: [],
      tasks: [],
      stats: [],
      dailies: [],
      promoCodes: [],
      activeTabKey: "cards",
      currentPage: 1,
      itemsPerPage: 50,
    } ),
  fetchCards: async () => {
    try {
      const response = await getCards()
      if ( response ) {
        // @ts-ignore
        set( { cards: response } )
      } else {
        console.error( "No cards found in response", response )
      }
    } catch ( error ) {
      console.error( "Error fetching cards:", error )
    }
  },
  fetchDailies: async () => {
    try {
      const response = await getDailies()
      if ( response ) {
        // @ts-ignore
        set( { dailies: response } )
      } else {
        console.error( "No dailies found in response", response )
      }
    } catch ( error ) {
      console.error( "Error fetching dailies:", error )
    }
  },
  fetchTasks: async () => {
    try {
      const response = await getTasks()
      if ( response ) {
        // @ts-ignore
        set( { tasks: response } )
      } else {
        console.error( "No tasks found in response", response )
      }
    } catch ( error ) {
      console.error( "Error fetching tasks:", error )
    }
  },
  fetchStats: async () => {
    try {
      const response = await getStats()
      if ( response ) {
        // @ts-ignore
        set( { stats: response } )
      } else {
        console.error( "No stats found in response", response )
      }
    } catch ( error ) {
      console.error( "Error fetching stats:", error )
    }
  },
  fetchPromoCodes: async () => {
    try {
      const response = await getPromoCodes()
      if ( response ) {
        // @ts-ignore
        set( { promoCodes: response } )
      } else {
        console.error( "No promo codes found in response", response )
      }
    } catch ( error ) {
      console.error( "Error fetching promo codes:", error )
    }
  },
} )

const useStore = create<MainStore>()(
  devtools(
    persist(
      ( ...a ) => ( {
        ...useMainStore( ...a ),
      } ),
      { name: "umperium-admin-store" },
    ),
  ),
)

export default useStore
