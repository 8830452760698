import React from "react"
import { Modal, Table } from "react-bootstrap"
import FormInput from "@/components/FormInput"
import { useForm, FormProvider } from "react-hook-form"

interface PreviewCardModalProps {
  show: boolean
  handleClose: () => void
  cardData: any
}

const PreviewCardModal: React.FC<PreviewCardModalProps> = ( { show, handleClose, cardData } ) => {
  const methods = useForm<{ levels: number }>( {
    defaultValues: { levels: 1 },
  } )

  const { watch } = methods
  const levels = watch( "levels" )

  const calculateValues = ( base: number, increment: number, levels: number ) => {
    return Array.from( { length: levels }, ( _, i ) => base * Math.pow( 1 + increment / 100, i ) )
  }

  const totalCost = calculateValues( cardData.base_price, cardData.price_increment, levels )
  const totalIncome = totalCost.map( cost => cost * cardData.income_percentage / 100 )

  return (
    <Modal
      show={ show }
      onHide={ handleClose }
      centered
      dialogClassName="custom-modal"
      contentClassName="custom-modal-content"
    >
      <Modal.Header closeButton style={ { backgroundColor: "#007bff", color: "#fff" } }>
        <Modal.Title>Предварительные расчёты</Modal.Title>
      </Modal.Header>
      <Modal.Body style={ { overflowY: "auto", backgroundColor: "#f5f5f5" } }>
        <FormProvider { ...methods }>
          <FormInput label="Количество уровней" name="levels" type="number" min="1" />
        </FormProvider>
        <Table striped bordered hover className="mt-3">
          <thead>
            <tr>
              <th>Уровень</th>
              <th>Стоимость улучшения</th>
              <th>Доход</th>
            </tr>
          </thead>
          <tbody>
            { totalCost.map( ( cost, index ) => (
              <tr key={ index }>
                <td>{ index + 1 }</td>
                <td>{ cost.toFixed( 2 ) }</td>
                <td>{ totalIncome[ index ].toFixed( 2 ) }</td>
              </tr>
            ) ) }
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  )
}

export default PreviewCardModal
