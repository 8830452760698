import { createTask as createTaskApi } from "@/api"
import FormInput from "@/components/FormInput"
import ImageUpload from "@/components/ImageUpload"
import { create_task_schema } from "@/schemas"
import useStore from "@/store"
import { zodResolver } from "@hookform/resolvers/zod"
import { useState } from "react"
import { Col, Row } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { toast } from "react-toastify"
import { TypeOf } from "zod"
import { LoadingButton } from "../LoadingButton"

export type taskInput = TypeOf<typeof create_task_schema>

interface TaskFormProps {
  handleClose: () => void
}

const CreateTaskForm: React.FC<TaskFormProps> = ( { handleClose } ) => {
  const methods = useForm<taskInput>( {
    resolver: zodResolver( create_task_schema ),
  } )
  const { fetchTasks, setRequestLoading, requestLoading, setActiveTabKey } = useStore()
  const [ file, setFile ] = useState<File | null>( null )

  const {
    formState: { errors, touchedFields },
  } = methods

  const createTask: SubmitHandler<taskInput> = async ( data ) => {
    try {
      setRequestLoading( true )

      const formData = new FormData()

      const formattedData = {
        ...data,
        title: { ru: data.title },
      }

      Object.entries( formattedData ).forEach( ( [ key, value ] ) => {
        if ( key !== "image" ) {
          formData.append( key, typeof value === 'object' ? JSON.stringify( value ) : value as string | Blob )
        }
      } )

      if ( file ) {
        formData.append( "image", file )
      }

      await toast.promise( createTaskApi( formData ), {
        pending: "Создание задания. Подождите...",
        success: "Задание успешно создано",
        error: "Ошибка при создании задания",
      } )

      await fetchTasks()

      setRequestLoading( false )
      methods.reset()
      setFile( null )
      handleClose()
      setActiveTabKey( 'tasks' )
    } catch ( error ) {
      console.error( "Error creating task:", error )
      toast.error( "Произошла ошибка при создании задания" )
    } finally {
      setRequestLoading( false )
    }
  }

  // @ts-ignore
  return (
    <FormProvider { ...methods }>
      <Form onSubmit={ methods.handleSubmit( createTask ) }>
        <ImageUpload onImageUpload={ setFile } />
        <Row>
          <Col xs={ 12 } md={ 6 }>
            <FormInput label="Заголовок" name="title" />
          </Col>
          <Col xs={ 12 } md={ 6 }>
            <FormInput
              label="Вознаграждение"
              name="bonus"
            />
          </Col>
        </Row>
        <Row>
          <Col >
            <FormInput
              label="Ссылка"
              name="url"
            />
          </Col>
        </Row>

        <Row>
          <LoadingButton
            loading={ requestLoading }
            btnColor={
              Object.keys( touchedFields ).length > 0 &&
                Object.keys( errors ).length === 0
                ? "success"
                : "secondary"
            }
          >
            Добавить задание в игру
          </LoadingButton>
        </Row>
      </Form>
    </FormProvider>
  )
}

export default CreateTaskForm
