import { createPromoCode as createPromoCodeApi } from "@/api"
import FormInput from "@/components/FormInput"
import { create_promo_schema } from "@/schemas"
import useStore from "@/store"
import { zodResolver } from "@hookform/resolvers/zod"
import { Col, Row, Form } from "react-bootstrap"
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { toast } from "react-toastify"
import { TypeOf } from "zod"
import { LoadingButton } from "../LoadingButton"
import { useState, useEffect } from "react"

export type PromoCodeInput = TypeOf<typeof create_promo_schema>

interface PromoCodeFormProps {
  handleClose: () => void
}

const PromoCodeForm: React.FC<PromoCodeFormProps> = ( { handleClose } ) => {
  const methods = useForm<PromoCodeInput>( {
    resolver: zodResolver( create_promo_schema ),
  } )
  const { fetchPromoCodes, setRequestLoading, requestLoading, setActiveTabKey } = useStore()
  const [ disableUsageLimit, setDisableUsageLimit ] = useState( false )

  useEffect( () => {
    methods.setValue( 'disableUsageLimit', disableUsageLimit )
  }, [ disableUsageLimit, methods ] )

  const createPromoCodeHandler: SubmitHandler<PromoCodeInput> = async ( data ) => {
    try {
      setRequestLoading( true )
      if ( disableUsageLimit ) {
        data.usage_limit = null
      }

      // @ts-ignore
      await toast.promise( createPromoCodeApi( data ), {
        pending: "Создание промокода. Подождите...",
        success: "Промокод успешно создан",
        error: "Ошибка при создании промокода",
      } )

      await fetchPromoCodes()
      methods.reset()
      handleClose()
      setActiveTabKey( 'promoCodes' )
    } catch ( error ) {
      console.error( "Error creating promo code:", error )
      toast.error( "Произошла ошибка при создании промокода" )
    } finally {
      setRequestLoading( false )
    }
  }

  return (
    <FormProvider { ...methods }>
      <Form onSubmit={ methods.handleSubmit( createPromoCodeHandler ) }>
        <Row>
          <Col xs={ 12 } md={ 6 }>
            <FormInput label="Промокод" name="code" />
          </Col>
          <Col xs={ 12 } md={ 6 }>
            <FormInput label="Бонус" name="bonus" />
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 } md={ 6 }>
            <Form.Check
              type="checkbox"
              label="Без лимита использования"
              onChange={ ( e ) => setDisableUsageLimit( e.target.checked ) }
              className="mb-3"
            />
            <FormInput
              label="Лимит использования"
              name="usage_limit"
              disabled={ disableUsageLimit }
            />
          </Col>
        </Row>
        <Row>
          <LoadingButton
            loading={ requestLoading }
            btnColor={
              Object.keys( methods.formState.touchedFields ).length > 0 &&
                Object.keys( methods.formState.errors ).length === 0
                ? "success"
                : "secondary"
            }
          >
            Создать промокод
          </LoadingButton>
        </Row>
      </Form>
    </FormProvider>
  )
}

export default PromoCodeForm
